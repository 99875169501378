import React from 'react'
import '../css/header.css'

/* IMAGES */
import logo from '../assets/logo.png'

function Header() {

    return (
        <>
            <header>
                <a className='logo' href="/"><img src={logo} alt="logo avvocato Rossana Sangiovanni" /></a>
                <nav className="menu">
                    <a className="menu__item" href="/">Home</a>
                    <a className="menu__item" href="/blog">Blog</a>
                    <a className="menu__item" href="/#chiSono">Chi sono</a>

                </nav>
                <a className="cta" href="/contatti">Contatti</a>
            </header>
        </>
    )
}

export default Header
