import React from 'react'
import { Link } from 'react-router-dom'
import '../css/esperto.css'

import Card from './cards/EspertoCards'

/* IMAGES */
import proprieta from '../assets/cards/proprieta.jpg'
import danni from '../assets/cards/danni.jpg'
import contratti from '../assets/cards/contratti.jpg'
import anelli from '../assets/anelli.jpg'
import tribunale from '../assets/cards/tribunale.jpg'
import mano from '../assets/cards/mano.jpg'
import responsabilita from '../assets/cards/responsabilita.jpg'


/* PDF */
import preventivo from '../assets/preventivo.pdf'

function Esperto() {
    return (
        <div className="esperto">
            <h2>ESPERTO IN</h2>
            <div className="grid">
                <Card style={{ gridArea: "elem1" }} data={{
                    img: anelli,
                    alt: "immagine divorzi",
                    title: "Diritto di Famiglia",
                    text: <p>Separazioni e Divorzi <br /> Unioni civili e coppie di fatto <br /> Diritto minorile <br /> Amm. di sostegno e inabilitazione e interdizione <br /> Diritti successori</p>
                }} />
                <Card style={{ gridArea: "elem2" }} data={{
                    img: proprieta,
                    alt: "immagine divorzi",
                    title: "Diritti reali",
                    text: <p>Diritto di proprietà <br /> Uso usucapione e servitù <br /> Locazioni e sfratti <br /> Controversie condominiali <br /> Assistenza acquisto immobili da aste giudiziarie</p>
                }} />
                <Card style={{ gridArea: "elem3" }} data={{
                    img: danni,
                    alt: "immagine divorzi",
                    title: "Risarcimento danni da",
                    text: <p>Responsabilità contrattuale e professionale<br /> Extracontrattuale <br /> Infortunistica stradale</p>
                }} />
                <Card style={{ gridArea: "elem4" }} data={{
                    img: contratti,
                    alt: "immagine divorzi",
                    title: "Contrattualistica",
                    text: <p className="different">Consulenza ed assistenza professionale per la redazione e la revisione di accordi, contratti, scritture private ed atti pubblici.  Tutela del cliente dalla fase preparatoria preliminare sino alla stipula ed all’esecuzione del contratto</p>
                }} />
                <Card style={{ gridArea: "elem5" }} data={{
                    img: responsabilita,
                    alt: "immagine divorzi",
                    title: "Recupero crediti ",
                    text: <p>Stragiudiziale e giudiziale <br /> Procedure esecutive</p>
                }} />
                <Card style={{ gridArea: "elem6" }} data={{
                    img: mano,
                    alt: "immagine divorzi",
                    title: "Mediazione e Negoziazione Assistita",
                    text: <p style={{ marginTop: "35px" }}>Assistenza all’interno delle procedure alternative al giudizio.</p>
                }} />
                <Card style={{ gridArea: "elem7" }} data={{
                    img: tribunale,
                    alt: "immagine divorzi",
                    title: "Domiciliazioni",
                    text: <p className="different">Il Servizio di domiciliazione legale è rivolto ai colleghi avvocati di altri fori che necessitano di essere sostituiti in udienza e/o di svolgere delle attività presso gli uffici giudiziari di Firenze, Empoli, Prato e Pistoia</p>
                }} />
            </div>
            <div className="grid__cta" style={{ gridArea: "cta" }}>
                <p><i>
                    Lo Studio, fornisce un primo incontro gratuito per conoscersi e mettere a fuoco l'argomento oggetto della consulenza. Con il conferimento  dell'incarico consegnerò un preventivo di massima relativo ai costi dell’attività da svolgere illustrando altresì il grado di complessità della prestazione.
                </i></p>
                <Link className="contatti" to="/contatti">Contattami</Link>
                <p className="preventivo">(Il preventivo sarà stilato in base all'attività legale che dovrà essere svolta ed ai <a className="preventivoFile" href={preventivo} target="blank">parametri ministeriali disciplinati dall'ex DMN. 147 del 13/08/2022</a>)</p>
            </div>
        </div>
    )
}

export default Esperto
