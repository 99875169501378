import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'

/* COMPONENTS */
import Header from './components/Header'
import Intro from './components/Intro'
import Esperto from './components/Esperto'
import Blog from './components/Blog'
import ChiSono from './components/ChiSono'
import Fromazione from './components/Formazione'
import Footer from './components/Footer'
import Contatti from './components/Contatti'

/* BLOG */
import Home from './components/blog/Home'
import Article from './components/blog/Article'

function App() {
  return (
    <Router>
      <Header />

      <Switch>
        <Route path="/" exact>
          <Intro />
          <Esperto />
          <ChiSono />
          <Blog />
          <Fromazione />
        </Route>

        <Route path="/contatti" component={Contatti} exact />

        <Route path="/blog" component={Home} exact />

        <Route path="/blog/articoli" component={Article} />

        <Route path="*">
          <h1 style={{ textAlign: "center", color: "#7e3e98" }}><a href="/">Errore, <br /> torna alla home</a></h1>
        </Route>
      </Switch>
      <Footer />
    </Router>
  )
}

export default App