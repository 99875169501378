import React from 'react'
import '../../css/articleCard.css'

function ArticleCard(props) {
    return (
        <div className="articleCard">
            <div className="articleCard__imgContainer">
               <img src={process.env.PUBLIC_URL + '/images/' + props.data.img} alt={props.data.imgAlt} /> 
            </div>
            <div className="articleCard__row"></div>
            <div className="articleCard__text">
                <h3>{props.data.title.toUpperCase()}<span>{props.data.date}</span></h3>
                <p>{props.data.text.substring(0,400) + '...'}</p>
                <a href={"/blog/articoli/" + props.data._id}>Leggi</a>
            </div>
        </div>
    )
}

export default ArticleCard
