import React from 'react'
import '../css/contatti.css'
import laurea from '../assets/laurea.png'
import biglietto from '../assets/visita.png'
import facebookIcon from '../assets/icons/facebook.png'
import instagramIcon from '../assets/icons/instagram.png'
import linkedinIcon from '../assets/icons/linkedin.png'

function Contatti() {
    return (
        <div className="contatto">
            <div className="contatto__intro">
                <div className="contatto__intro__intro">
                    <div className="contatto__social">
                        <h1>Rimani aggiornato sui miei social</h1>
                        <div className="contatto__social__icons">
                            <a
                                href="https://www.facebook.com/people/Avv-Rossana-Maria-Sangiovanni/100064872813252" target='_blank'
                                rel="noreferrer"
                            >
                                <img src={facebookIcon} alt="facebook logo" />
                                Facebook
                            </a>
                            <a
                                href="https://www.instagram.com/avvocato_sangiovanni/"
                                target='_blank'
                                rel="noreferrer"
                            >
                                <img src={instagramIcon} alt="instagram logo" />
                                Instagram
                            </a>
                            <a
                                href="https://it.linkedin.com/in/rossana-maria-sangiovanni-1521789b"
                                target='_blank'
                                rel="noreferrer"
                            >
                                <img src={linkedinIcon} alt="linkedin logo" />
                                Linkedin
                            </a>
                        </div>
                    </div>
                    <div className="visita">
                        <img src={biglietto} alt="biglietto da visita" />
                    </div>
                </div>
                <div className="contatto__intro__second">
                    <img src={laurea} alt="diploma laurea" />
                </div>
            </div>
        </div>
    )
}

export default Contatti
