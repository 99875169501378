/* 
  ITEM:
  {
    "_id": "",
    "title": "",
    "text": "",
    "img": "",
    "imgAlt": "",
    "date": "DD-MM-YYYY"
  },
*/

/* TENERE ORDINATI IN ALTO IL PIÙ RECENTE */

const articoli = [
  {
    "_id": "60be65fbe4aa61001543d618",
    "title": "Diritto al mantenimento e dovere di auto-responsabilità del figlio laureato",
    "text": "Il figlio ultramaggiorenne richiedente l'assegno di mantenimento al genitore non convivente deve dimostrare di avere curato, con ogni possibile impegno, la propria preparazione professionale o tecnica o di essersi, con pari impegno, attivato nella ricerca di un lavoro; Nel caso di specie si trattava di laureata in legge in procinto di diventare avvocato.\nLe cose cambiano se si tratta di neomaggiorenne che prosegua gli studi superiori, universitari o di specializzazione, è sufficiente tale circostanza a determinare il suo diritto al mantenimento mentre il \"figlio adulto\" deve essere applicato il principio dell'autoresponsabilità",
    "img": "./figlio.webp",
    "imgAlt": "Ragazzo che fuma",
    "date": "04-03-2024"
  },
  {
    "_id": "65eb5e6ad80de68d11e074b6",
    "title": "BONUS PER GENITORI SEPARATI, DIVORZIATI E/O NON CONVIVENTI",
    "text": "Previsto per i genitori in stato di bisogno, con un reddito inferiore ad € 8.174,00, e che nel periodo di emergenza epidemiologica Covid 19 non hanno percepito assegno di mantenimento per i figli con loro conviventi , minori o maggiorenni portatori di grave handicap.\nE' possibile richiedere il bonus soltanto se l'altro genitore, in conseguenza dell'emergenza da Covid 19, abbia cessato, ridotto o sospeso l'attività lavorativa a decorrere dall'8 marzo 2020 per una durata minima di 90 gg oppure abbia subito una riduzione del reddito almeno del 30% rispetto a quello percepito nel 2019. \nLa domanda deve essere presentata previa autenticazione al portale dell'INPS dal 12 FEBBRAIO 2024 al 30 MARZO 2024 \nL'Inps ha chiarito che tale bonus spetta anche in assenza di matrimonio purchè siano rispettati i requisiti soprariportati.\nL'agevolazione verrà corrisposta in un'unica soluzione calcolata in base all'importo non versato dell'assegno di mantenimento e fino a concorrenza di 800 euro mensili, per un massimo di 12 mensilità e tenendo conto delle disponibilità del fondo che è pari a 10 milioni di euro.",
    "img": "./famiglia.jpg",
    "imgAlt": "Famgilia separata",
    "date": "15-02-2024"
  },
  {
    "_id": "65eb5fbd614951bc685bd36b",
    "title": "Illegittima la sanzione per omessa comunicazione dei dati del conducente se l'infrazione è annullata",
    "text": "Cass. n. 3022 del 01/02/2024\nNel caso di Infrazione al Codice della Strada che comportI anche la detrazione dei punti dalla patente del conducente, annullata a seguito di ricorso in opposizione E' ILLEGITTIMO APPLICARE LA SANZIONE PER OMESSA COMUNICAZIONE DEI DATI DEL CONDUCENTE, ex ART.126 bis 2 c.d.s. poichè viene meno il presupposto della violazione e si tratterebbe di un indebito arricchimento.\nNel caso di rigetto dell'opposizione, l'autorità competente è obbligata a notificare nuova richiesta di comunicazione dei dati del conducente e da tale data matureranno i 60gg ex art. 126bis 2 c.d.s.",
    "img": "./autovelox.jpg",
    "imgAlt": "autovelox",
    "date": "12-02-2024"
  },
  {
    "_id": "65eb5fb8996648e05c187d03",
    "title": "Scioglimento dell’unione civile: ai fini dell’assegno si tiene conto anche della durata della convivenza di fatto",
    "text": "CASS. CIV. Sez. Unite N. 3569/2023.\nIn caso di scioglimento di Unione civile,il criterio di valutazione dei presupposti necessari al riconoscimento del diritto all'assegno di mantenimento della parte che non disponga di mezzi adeguati o non sia in grado di procurarseli si estende al periodo di convivenza di fatto che abbia preceduto la formalizzazione dell'unione civile, anche per quelle convivenze nate antecedentemente all'entrata in vigore della L.76/2016 poichè fino ad allora era vietato il riconoscimento formale di famiglie di fatto omosessuali",
    "img": "./anello.jpg",
    "imgAlt": "Rimozione fede nuziale",
    "date": "07-02-2024"
  },
  {
    "_id": "65eb6017049d1410428df001",
    "title": "BONUS PATENTE AUTOTRASPORTO",
    "text": "Decreto 12 del 30/01/23,\"pari all’80 per cento della spesa sostenuta e comunque di importo non superiore a 2.500 euro, in favore dei cittadini di età compresa fra diciotto e trentacinque anni per il conseguimento della patente e delle abilitazioni professionali per la guida dei veicoli destinati all’esercizio dell’attività di autotrasporto di persone e di merci”.\nPer poter accedere al bonus è necessario entrare sulla piattaforma del MIT, https://patentiautotrasporto.mit.gov.it/bonuspatente/,e compilare il modulo ivi presente. Il bonus verrà erogato soltanto a seguito dell'ottenimento della patente.",
    "img": "./patente.jpg",
    "imgAlt": "Patente italiana",
    "date": "13-03-2023"
  },
  {
    "_id": "65eb605e76185168343d304d",
    "title": "CASA COSTRUITA SUL TERRENO DEL CONVIVENTE : L'EX HA DIRITTO AL RIMBORSO PER LE SPESE SOSTENUTE",
    "text": "Il convivente che ha contribuito finanziariamente e con il proprio lavoro a costruire la casa familiare sul terreno di esclusiva proprietà dell'ex patner, una volta cessata la convivenza , ha diritto di richiedere quanto speso per la costruzione ed essere indennizzato il suo lavoro. Possibile adire il tribunale civile di competenza con un'azione di arricchimento senza giusta causa ma dovrà comunque essere provato l'apporto economico e lavorativo del convivente non proprietario (Cass. Civ. Ord. 16/02/2022 n.5086)",
    "img": "./casa.jpg",
    "imgAlt": "casa in costruzione",
    "date": "26-04-2022"
  },
  {
    "_id": "65eb60d275cc7f13066dcaa6",
    "title": "LA COMPENSAZIONE PECUNIARIA ( risarcimento automatico in base ai KM della tratta aerea)",
    "text": "PER VOLO CON UN RITARDO SUPERIORE ALLE TRE ORE di compagnia aerea con sede nella UE e che parte da un paese UE riconosciuta automaticamente in forza del . REG. CE 261/04.\nLa Corte di Giustizia Europea ha riconosciuto il diritto del passeggero ad ottenere la compensazione pecuniaria per il ritardo accumulato anche nel caso di un'unica prenotazione per un viaggio da Bruxelles a SanCosè con scalo a Newark, con la prima tratta gestita da Lufthansa e la seconda da American Airlines.\nIl vetttore aereo extra – europeo ha risposto del ritardo del volo operato su una tratta extra- europea poiché è stato considerato parte integrante di un unico contratto di traspostro concluso dal passeggero con un vetttore europeo.\nPer la quantificazione della compensazione pecuniaria è stata considerata la distanza tra il luogo del primo decollo e quello della destinazione finale.\nIl Reg. Ce. 261/04 quantifica la compensazione pecuniaria in: € 250 per tratte inferiori ai 1.500km, € 400 per tratte tra 1.500 e 3.500km, € 600 oltre il 3.500 km.",
    "img": "./aereo.jpg",
    "imgAlt": "Aereo in volo",
    "date": "22-04-2022"
  },
  {
    "_id": "65ef43ef416ba2828b9a19ea",
    "title": "L'ASSEGNAZIONE DELLA CASA FAMILIARE AL CONIUGE AFFIDATARIO DEI FIGLI ",
    "text": "L'assegnazione della casa familiare al genitore con il quale i minori trascorrono maggior parte del tempo risponde all’esigenza di conservare a questi ultimi l’habitat domestico, inteso come il centro degli affetti, degli interessi e delle consuetudini in cui si esprime e si articola la vita familiare. Ne consegue che vada revocata qualora si dimostri rigorosamente che i minori non vi abitino più stabilmente ed in maniera irreversibile. Il giudice dovrà comunque essere certo che tale misura non contrasti con gli interessi della prole. ( Cass. Civ. sez I ord. del 31/03/2022 n.10453) ",
    "img": "./disegno.jpg",
    "imgAlt": "disegno casa bambino",
    "date": "13-04-2022"
  }
]

module.exports = articoli