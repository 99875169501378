import React from 'react'
import '../css/footer.css'

/* IMAGES */
import phone from '../assets/phone.png'
import placeholder from '../assets/placeholder.png'
import clock from '../assets/clock.png'
import mail from '../assets/mail.png'

function Footer() {
    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="contatti">
                    <h3>AVVOCATO ROSSANA MARIA <br /> SANGIOVANNI</h3>
                    <div className="item">
                        <img src={phone} alt="phone icon" />
                        <a href="tel:0550456659">0550456659</a>
                    </div>
                    <div className="item" style={{marginTop: "12px"}}>
                        <img src={placeholder} alt="placeholder icon"  style={{width: "30px", marginLeft: "2px"}} />
                        <p style={{marginLeft: "-8px"}}>Via Cassia, 4 Firenze</p>
                    </div>
                    <div className="item">
                        <img src={clock} alt="clock icon"/>
                        <p>9:00 -18:00 Lun - Ven</p>
                    </div>
                    <div className="item" style={{marginTop: "10px"}}>
                        <img src={mail} alt="mail icon"/>
                        <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%61%76%76%73%61%6E%67%69%6F%76%61%6E%6E%69%66%69%40%67%6D%61%69%6C%2E%63%6F%6D">avvsangiovannifi@gmail.com</a>
                    </div>
                    <div className="item" style={{marginTop: "18px"}}>
                        <img src={mail} alt="mail icon"/>
                        <a style={{fontSize: "13px", marginTop:"2px"}} href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#114;&#111;&#115;&#115;&#97;&#110;&#97;&#46;&#109;&#97;&#114;&#105;&#97;&#46;&#115;&#97;&#110;&#103;&#105;&#111;&#118;&#97;&#110;&#110;&#105;&#64;&#102;&#105;&#114;&#101;&#110;&#122;&#101;&#46;&#112;&#101;&#99;&#97;&#118;&#118;&#111;&#99;&#97;&#116;&#105;&#46;&#105;&#116;">rosanna.maria.sangiovanni@firenze.pecavvocati.it</a>
                    </div>
                </div>
                <div className="row"></div>
                <div className="mappa"><iframe title="mappa" style={{border:"0", height: "100%", width: "100%"}} loading="lazy" allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ74SwoKRWKhMRm9uErBUT2PI&key=AIzaSyC2q-98BtvQBB07ymsKa5Y0iRcgHEcidj8"></iframe></div>
            </div>
        </footer>
    )
}

export default Footer
