import React from 'react'
import '../../css/blog/home.css'
import '../../css/blog.css'

import Cards from '../cards/ArticleCard'
import articoli from '../Articoli/articoli'

class home extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            articles: articoli
        }
    }


    render(){

        function convertiData(data){
            const mesi = ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic']
            let arr = data.substring(0,10).split('-').reverse()
            arr[1] = mesi[Number(arr[1] - 1)]
            return arr.join(' ')
        }

         return (
            <div className="blog home">
                <h1 style={{paddingTop: "30px", textAlign: "center", color: "#7e3e98"}}>I MIEI ARTICOLI</h1>
                {this.state.articles.map(elem => {return <Cards key={elem._id} data={{
                    img: elem.img,
                    imgAlt: elem.imgAlt,
                    title: elem.title,
                    text: elem.text,
                    date: convertiData(elem.date),
                    _id: elem._id
                }}/>})}
            </div>
        )   
    }
}

export default home
