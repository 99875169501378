const corsi = [
  {
    "_id": "65eb5bce556ae7fa0faca3c7",
    "title": "Violenza di genere, Codice Rosso, Codice Rosa. Confronto tra i professionisti sugli aspetti legali, medici e psicologici dalle vittime di violenza",
    "date": "2024-02-29T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5bc946190e28807dcf02",
    "title": "Decreti Ingiuntivi, la tutela del consumatore. Rassegna delle clausole abusive più ricorrenti alla 	luce della S. n. 4079/2023 delle S.U. Corte di Cassazione",
    "date": "2024-01-30T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5bc722b66c10b51d7594",
    "title": "Il Piano Genitoriale nell'interesse del minore. Ratio, scopo e applicazione",
    "date": "2023-09-22T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5bc4879d743763aafdcf",
    "title": "La mediazione familiare: novità introdotte dalla Riforma Cartabia",
    "date": "2023-07-06T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5bc1409fd9038bd614cb",
    "title": "La nuova disciplina dell'Equo compenso",
    "date": "2023-07-04T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5bbf656ab629e084b552",
    "title": "La riforma del Processo Civile",
    "date": "2023-05-09T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5bb91fd081e88aa8d89d",
    "title": "Corso excel per Avvocati",
    "date": "2022-11-02T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5bb6417c2968fbd84457",
    "title": "Riforma della giustizia Civile, prima lettura della legge delega",
    "date": "2022-04-14T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5bb3ba7e04001c32ce76",
    "title": "Assegno divorzile e nuova convivenza del beneficiario. Commento alla Sent.  Cass. Sez Unite n.3218/201",
    "date": "2022-01-11T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5bafcb2c59ca06972ab9",
    "title": "Il conflitto di interessi del minore con i genitori nei procedimenti di famiglia e rappresentanza del minore",
    "date": "2021-11-24T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "65eb5a391cd3fe986d07c082",
    "title": "La dichiarazione di successione e la nuova modalità telematica",
    "date": "2021-09-21T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c339064919af0015240b92",
    "title": "Corso breve sulle successioni: l'acquisto dell'eredità e le problematiche relative alla rinuncia ",
    "date": "2021-05-26T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c338f74919af0015240b91",
    "title": "I diritti inviolabili del minore dopo le sentenze della corte costituzionale del 09.03.2021",
    "date": "2021-04-29T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c338e44919af0015240b90",
    "title": "Notificazione in proprio dell'avvocato a mezzo pec di atti e provvedimenti. le attestazioni di conformità, l'utilizzo della posta elettronica certificata e della firma digitale ",
    "date": "2021-04-27T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c338cc4919af0015240b8f",
    "title": "Gli assegni di mantenimento tra disciplina ed intelligenza artificiale  ",
    "date": "2021-04-09T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c3386b4919af0015240b8d",
    "title": "L'accesso agli atti della pubblica amministrazione e la ricerca telematica dei beni quali strumenti istruttori nei procedimenti di famiglia ",
    "date": "2021-03-03T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c338474919af0015240b8c",
    "title": "A domanda risponde: ragionevoli dubbi in materia di specializzazioni forensi ",
    "date": "2021-03-01T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c3378d4919af0015240b8b",
    "title": "Figli che rifiutano i genitori: profili giuridici e psicologici ",
    "date": "2021-01-22T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33a454919af0015240b9e",
    "title": "(3° incontro) lezioni sulle scabrosità dei processi di diritto di famiglia  ",
    "date": "2020-10-07T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33a354919af0015240b9d",
    "title": "Patrocinio a spese dello stato: tutela dei diritti nel prisma dei problemi attuativi",
    "date": "2020-09-29T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33a234919af0015240b9c",
    "title": "Il condominio tra dubbi, lacune e problemi post covid-19  ",
    "date": "2020-09-24T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33a144919af0015240b9b",
    "title": "Il processo di famiglia ai tempi del coronavirus ",
    "date": "2020-07-23T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33a094919af0015240b9a",
    "title": "Lezioni di diritto processuale civile",
    "date": "2020-06-26T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c339f94919af0015240b99",
    "title": "(1° incontro) lezioni sulle scabrosità dei processi di diritto di famiglia ",
    "date": "2020-06-23T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c339ec4919af0015240b98",
    "title": "La mediazione in videoconferenza: la normativa e la prassi funzionale ",
    "date": "2020-06-19T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c339d64919af0015240b97",
    "title": "Diritto processuale della famiglia: riflessioni e analisi di alcune pronunce della corte di cassazione",
    "date": "2020-06-16T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c339ba4919af0015240b96",
    "title": "La ricerca di nuovi clienti: gli obblighi deontologici",
    "date": "2020-05-22T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c3399c4919af0015240b95",
    "title": "Figli maggiorenni vs. genitori: diritti e doveri  ",
    "date": "2020-05-15T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c3398d4919af0015240b94",
    "title": "Solidarieta' post familiare dopo la sentenza ss. uu. n.18267/2018",
    "date": "2020-05-08T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c339784919af0015240b93",
    "title": "Il diritto al tempo del covid-19. l'impatto dei provvedimenti emergenziali: riflessioni a prima lettura ",
    "date": "2020-04-29T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33c334919af0015240bae",
    "title": "Gdpr e minori: un valido contributo per contrastare il fenomeno del cyberbullismo ",
    "date": "2019-10-24T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33c284919af0015240bad",
    "title": "L'avvocato 4.0: come emergere nell'era digitale nel rispetto del codice deontologico. regole e strategie",
    "date": "2019-09-24T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33c1d4919af0015240bac",
    "title": "(i edizione 2019) giurisprudenza e prassi nel processo civile telematico ",
    "date": "2019-06-25T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33c0f4919af0015240bab",
    "title": "L'avvocato nel nuovo diritto sportivo: fondamenti di diritto sostanziale e conflitti deontologici ",
    "date": "2019-04-12T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33c054919af0015240baa",
    "title": "(i replica) negoziazione assistita con il supporto di un esperto in mediazione familiare: opportunità per l'avvocato ",
    "date": "2019-03-29T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33bf94919af0015240ba9",
    "title": "Il nuovo assegno divorzile e le prime esperienze applicative ",
    "date": "2018-11-29T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33bed4919af0015240ba8",
    "title": "L'assegno di divorzio dopo la sentenza ss.uu. 11/07/2018 n. 18287: primi spunti di riflessione  ",
    "date": "2018-09-18T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33be44919af0015240ba7",
    "title": "Gdpr (privacy) nello studio legale. cosa cambia, cosa fare, come farlo, e come gestire ",
    "date": "2018-06-08T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33bc44919af0015240ba4",
    "title": "Corso base di diritto processuale della famiglia ",
    "date": "2018-05-31T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33bd84919af0015240ba6",
    "title": "Questioni in tema di compenso professionale",
    "date": "2018-05-15T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33bce4919af0015240ba5",
    "title": "Dal bullo al cyberbullismo perdita di valori o mancanza di direzione. riflessioni a partire dalla legge n. 71/2017 ",
    "date": "2018-05-09T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33bba4919af0015240ba3",
    "title": "Incontro di presentazione regole di protocollo in materia di locazioni ",
    "date": "2018-04-23T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33bae4919af0015240ba2",
    "title": "Il regolamento 'privacy' u.e. 2016/679: adempiere cercando la semplicità ",
    "date": "2018-04-16T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33ba34919af0015240ba1",
    "title": "Essere avvocato (quello che non puoi non sapere se vuoi fare questa professione) ",
    "date": "2018-04-09T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33b574919af0015240ba0",
    "title": "2° incontro - gestire lo studio legale: il rapporto di lavoro con il praticante ",
    "date": "2017-07-18T00:00:00.000Z",
    "__v": 0
  },
  {
    "_id": "60c33a964919af0015240b9f",
    "title": "Unioni e disunioni della coppia",
    "date": "2017-03-10T00:00:00.000Z",
    "__v": 0
  }
]

module.exports = corsi 