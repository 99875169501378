import React, { Component } from 'react'
import '../../css/blog/article.css'
import Sidebar from './Sidebar'
import articoli from '../Articoli/articoli'

export default class Article extends Component {
    constructor(props) {
        super(props)
        this.state = {
            article: {},
            articoli: articoli
        }
    }

    componentDidMount() {
        let id = window.location.pathname.replace('/blog/articoli/', '')
        let nuovo = this.state.articoli.filter(elem => elem._id === id)[0]
        nuovo.text = nuovo.text.split('\n').map((str, index) => {
            return <p key={index}>{str}</p>
        })
        this.setState({ article: nuovo })
    }

    articleTextDisplay = () => {
        let text = this.state.article.text
        let newText = text.split('\n').map((str, index) => {
            return <p key={index}>{str}</p>
        })
        return newText
    }

    render() {
        return (
            <div className="articolo">
                <div className="articolo__intro">
                    <img src={process.env.PUBLIC_URL + '/images/' + this.state.article.img} alt="immagine intro Articolo" />
                    <h1>{this.state.article.title}</h1>
                </div>
                <div className="content">
                    <div className="articolo__text">
                        <p>{this.state.article.text}</p>
                        <a className="articolo__button" href="/blog">Vai al blog</a>
                    </div>

                    <div className="sidebar">
                        <h3>Ultimi articoli</h3>
                        <ul>
                            {this.state.articoli.slice(0, 5).map(elem => { return elem._id !== this.state.article._id ? <Sidebar data={elem} key={elem._id} /> : null })}
                        </ul>
                    </div>
                </div>
            </div >
        )
    }
}
