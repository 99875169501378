import React from 'react'
import '../../css/sidebar.css'

function Sidebar(props) {
    return (
        <>
            <li className='line-clamp'>
                <a href={"/blog/articoli/" + props.data._id}>{props.data.title}</a>
            </li>
        </>
    )
}

export default Sidebar
