import React from 'react'
import '../css/blog.css'

import ArticleCard from './cards/ArticleCard'
import articoli from './Articoli/articoli'

class Blog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            articles: articoli
        }
    }

    render() {

        function convertiData(data) {
            const mesi = ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic']
            let arr = data.substring(0, 10).split('-').reverse()
            arr[1] = mesi[Number(arr[1] - 1)]
            return arr.join(' ')
        }

        return (
            <div className="blog">
                <h2>I MIEI ARTICOLI</h2>
                <div className="blog__articoli" id="blog">
                    {this.state.articles.length !== 0 ? this.state.articles.reverse().slice(-3).map(elem => <ArticleCard key={elem._id} data={{
                        img: elem.img,
                        imgAlt: elem.imgAlt,
                        title: elem.title,
                        text: elem.text,
                        date: convertiData(elem.date),
                        _id: elem._id
                    }} />) : <h3 style={{ fontSize: "26px", color: "red", textAlign: "center", padding: "40px 0" }}>Errore di connessione <br /><span onClick={() => window.location.reload()} style={{ textDecoration: "underline", cursor: "pointer" }}>RICARICA LA PAGINA</span></h3>}
                </div>
                <a className="link" href="/blog">VISITA IL BLOG</a>
            </div>
        )
    }
}

export default Blog
